import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../components/Common/withRouter";
import TableContainer from "../components/Common/TableContainer";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    UncontrolledTooltip,
    Input,
    Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteModal from "../components/Common/DeleteModal";

import {
    getCounters as onGetCounters,
    addNewCounter as onAddNewCounter,
    updateCounter as onUpdateCounter,
    deleteCounter as onDeleteCounter,
} from "../store/counter/actions";
import { isEmpty } from "lodash";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import moment from 'moment';
import CategorySelect from "components/Common/CategorySelect";
import { CustomBadge } from "components/Common/ColumnStyle";
const Counters = props => {

    //meta title
    document.title = "Mythreya - Counters";

    const dispatch = useDispatch();
    const [counter, setCounter] = useState();
    const [selectedCategory, setSelectedCategory] = useState([])
    const [selectCategory, setSelectCategory] = useState([]);

    const [isSubmitted, setIsSubmitted] = useState(false);

    const changeCategory = (val) => {
        validation.setFieldValue("category", val)
        setSelectedCategory(val)
    }

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: (counter && counter.name) || "",
            category: (counter && counter.category) || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            category: Yup.array().required("Please select category").min(1, "Please select category"),
        }),
        onSubmit: values => {
            if (isEdit) {
                let categories = []
                selectedCategory.forEach((value, index) => {
                    categories.push(value.value);
                });
                const updateCounter = {
                    id: counter.id,
                    name: values.name,
                    category:categories
                };
                dispatch(onUpdateCounter(updateCounter));
                setIsEdit(false);
                validation.resetForm();
            } else {
                let categories = []
                selectedCategory.forEach((value, index) => {
                    categories.push(value.value);
                });
                const newCounter = {
                    name: values["name"],
                    category:categories
                };
                // save new user
                dispatch(onAddNewCounter(newCounter));
                validation.resetForm();
            }
            toggle();
        },
    });

    const selectCountersState = (state) => state.counters;
    const CountersProperties = createSelector(
        selectCountersState,
        (Counter) => ({
            counters: Counter.counter,
            loading: Counter.loading
        })
    );

    const {
        counters, loading
    } = useSelector(CountersProperties);

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setLoading] = useState(loading)
    const [counterList, setCounterList] = useState([]);
    const columns = useMemo(
        () => [
            {
                Header: "#",
                // accessor: "name",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps) => (
                    <>
                        {!cellProps.img ? (
                            <div className="avatar-xs">
                                <span className="avatar-title rounded-circle">
                                    {cellProps.name.charAt(0)}
                                </span>
                            </div>
                        ) : (
                            <div>
                                <img
                                    className="rounded-circle avatar-xs"
                                    src={cellProps.img}
                                    alt=""
                                />
                            </div>
                        )}
                    </>
                ),
            },
            {
                Header: "Name",
                accessor: "name",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.name}</Link>
                            </h5>
                            <p className="text-muted mb-0">{cellProps.row.original.designation}</p>
                        </>
                    )
                },
            },
            {
                Header: "Categiry",
                accessor: "category",
                filterable: true,
                Cell: cellProps => {
                    return <CustomBadge color="info" {...cellProps} />;
                },
            },
            {
                Header: "Created By",
                accessor: "createdBy",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.createdBy?.name}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Created Date",
                accessor: "createdAt",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{moment(cellProps.row.original.createdAt).format('MMM D, YYYY')}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Action",
                Cell: cellProps => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-success"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    handleCounterClick(userData);
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    onClickDelete(userData);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                },
            },
        ],
        []
    );

    useEffect(() => {
        if (counters && !counters.length) {
            dispatch(onGetCounters());
            setIsEdit(false);
        }
    }, [dispatch, counters]);

    useEffect(() => {
        setCounter(counters);
        setIsEdit(false);
    }, [counters]);

    useEffect(() => {
        if (!isEmpty(counters) && !!isEdit) {
            setCounter(counters);
            setIsEdit(false);
        }
    }, [counters]);

    const toggle = () => {
        setModal(!modal);
    };

    const handleCounterClick = arg => {
        const counter = arg;
        
        setSelectCategory(counter.category)
        let categories = counter.category.map((x) => { return { label: x.name, value: x._id } })
        setSelectedCategory(categories)
        
        setCounter({
            id: counter.id,
            name: counter.name
        });

        validation.initialValues = {
            name: (counter && counter.name) || "",
            category: (counter && counter.category) || "",
        }
        setIsEdit(true);

        toggle();
    };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    //delete customer
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = counters => {
        setCounter(counters);
        setDeleteModal(true);
    };

    const handleDeleteCounter = () => {
        if (counter && counter.id) {
            dispatch(onDeleteCounter(counter.id));
        }
        onPaginationPageChange(1);
        setDeleteModal(false);
    };

    const handleCounterClicks = () => {
        setCounterList("");
        setIsEdit(false);
        setSelectCategory([])
        setSelectedCategory([])
        validation.resetForm();
        toggle();
    };

    const keyField = "id";

    return (
        loading ? <Spinners setLoading={setLoading} />
            :
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteCounter}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs breadcrumbItem="Counters" />
                    <Row>
                        {
                            isLoading ? <Spinners setLoading={setLoading} />
                                :
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                isPagination={true}
                                                columns={columns}
                                                data={counters}
                                                isGlobalFilter={true}
                                                isShowingPageLength={true}
                                                buttonText={"Create Counter"}
                                                isAddUserList={true}
                                                iscustomPageSizeOptions={true}
                                                handleUserClick={handleCounterClicks}
                                                customPageSize={10}
                                                tableClass="table align-middle table-nowrap table-hover"
                                                theadClass="table-light"
                                                paginationDiv="col-sm-12 col-md-7"
                                                pagination="pagination pagination-rounded justify-content-end mt-4"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                        }

                        <Modal isOpen={modal} toggle={toggle}>
                            <ModalHeader toggle={toggle} counter="h4">
                                {!!isEdit ? "Edit Counter" : "Add Counter"}
                            </ModalHeader>
                            <ModalBody>
                                <Form
                                    onSubmit={e => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}
                                >
                                    <Row>
                                        <Col xs={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">Name</Label>
                                                <Input
                                                    name="name"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.name || ""}
                                                    invalid={
                                                        validation.touched.name &&
                                                            validation.errors.name
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.touched.name &&
                                                    validation.errors.name ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.name}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label>Select Category</Label>
                                                <CategorySelect isMulti={true} isSubmitted={isSubmitted} error={validation.errors.category} defaultValue={selectCategory} OnSelect={changeCategory}></CategorySelect>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="text-end">
                                                <button
                                                    type="submit"
                                                    className="btn btn-success save-user"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </ModalBody>
                        </Modal>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default withRouter(Counters);
