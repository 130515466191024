import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../components/Common/withRouter";
import TableContainer from "../components/Common/TableContainer";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    UncontrolledTooltip,
    Input,
    Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteModal from "../components/Common/DeleteModal";

import {
    getStoreMetrics as onGetStoreMetrics,
    addNewStoreMetric as onAddNewStoreMetric,
    updateStoreMetric as onUpdateStoreMetric,
    deleteStoreMetric as onDeleteStoreMetric,
} from "../store/storemetric/actions";
import { isEmpty } from "lodash";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import moment from 'moment';
const StoreMetrics = props => {

    //meta title
    document.title = "Mythreya - StoreMetrics";

    const dispatch = useDispatch();
    const [storeMetric, setStoreMetric] = useState();
    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: (storeMetric && storeMetric.name) || "",
            value: (storeMetric && storeMetric.value) || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            value: Yup.string().required("Please Enter Value"),
        }),
        onSubmit: values => {
            if (isEdit) {
                const updateStoreMetric = {
                    id: storeMetric.id,
                    name: values.name,
                    value : values.value
                };
                dispatch(onUpdateStoreMetric(updateStoreMetric));
                setIsEdit(false);
                validation.resetForm();
            } else {
                const newStoreMetric = {
                    name: values["name"],
                    value: values["value"]
                };
                // save new user
                dispatch(onAddNewStoreMetric(newStoreMetric));
                validation.resetForm();
            }
            toggle();
        },
    });

    const selectStoreMetricsState = (state) => state.storeMetrics;
    const StoreMetricsProperties = createSelector(
        selectStoreMetricsState,
        (StoreMetrics) => ({
            storeMetrics: StoreMetrics.storeMetric,
            loading: StoreMetrics.loading
        })
    );

    const {
        storeMetrics, loading
    } = useSelector(StoreMetricsProperties);

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setLoading] = useState(loading)
    const [storeMetricList, setStoreMetricList] = useState([]);
    const columns = useMemo(
        () => [
            {
                Header: "#",
                // accessor: "name",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps) => (
                    <>
                        {!cellProps.img ? (
                            <div className="avatar-xs">
                                <span className="avatar-title rounded-circle">
                                    {cellProps.name.charAt(0)}
                                </span>
                            </div>
                        ) : (
                            <div>
                                <img
                                    className="rounded-circle avatar-xs"
                                    src={cellProps.img}
                                    alt=""
                                />
                            </div>
                        )}
                    </>
                ),
            },
            {
                Header: "Name",
                accessor: "name",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.name}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Value",
                accessor: "value",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.value}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Created By",
                accessor: "createdBy",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.createdBy?.name}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Created Date",
                accessor: "createdAt",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{moment(cellProps.row.original.createdAt).format('MMM D, YYYY')}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Action",
                Cell: cellProps => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-success"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    handleStoreMetricClick(userData);
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    onClickDelete(userData);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                },
            },
        ],
        []
    );

    useEffect(() => {
        if (storeMetrics && !storeMetrics.length) {
            dispatch(onGetStoreMetrics());
            setIsEdit(false);
        }
    }, [dispatch, storeMetrics]);

    useEffect(() => {
        setStoreMetric(storeMetrics);
        setIsEdit(false);
    }, [storeMetrics]);

    useEffect(() => {
        if (!isEmpty(storeMetrics) && !!isEdit) {
            setStoreMetric(storeMetrics);
            setIsEdit(false);
        }
    }, [storeMetrics]);

    const toggle = () => {
        setModal(!modal);
    };

    const handleStoreMetricClick = arg => {
        const user = arg;

        setStoreMetric({
            id: user.id,
            name: user.name,
            value: user.value,
        });
        setIsEdit(true);

        toggle();
    };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    //delete customer
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = storeMetrics => {
        setStoreMetric(storeMetrics);
        setDeleteModal(true);
    };

    const handleDeleteStoreMetric = () => {
        if (storeMetric && storeMetric.id) {
            dispatch(onDeleteStoreMetric(storeMetric.id));
        }
        onPaginationPageChange(1);
        setDeleteModal(false);
    };

    const handleStoreMetricClicks = () => {
        setStoreMetricList("");
        setIsEdit(false);
        toggle();
    };

    const keyField = "id";

    return (
        loading ? <Spinners setLoading={setLoading} />
            :
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteStoreMetric}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs breadcrumbItem="StoreMetrics" />
                    <Row>
                        {
                            isLoading ? <Spinners setLoading={setLoading} />
                                :
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                isPagination={true}
                                                columns={columns}
                                                data={storeMetrics}
                                                isGlobalFilter={true}
                                                isShowingPageLength={true}
                                                buttonText={"Create StoreMetric"}
                                                isAddUserList={true}
                                                iscustomPageSizeOptions={true}
                                                handleUserClick={handleStoreMetricClicks}
                                                customPageSize={10}
                                                tableClass="table align-middle table-nowrap table-hover"
                                                theadClass="table-light"
                                                paginationDiv="col-sm-12 col-md-7"
                                                pagination="pagination pagination-rounded justify-content-end mt-4"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                        }

                        <Modal isOpen={modal} toggle={toggle}>
                            <ModalHeader toggle={toggle} tag="h4">
                                {!!isEdit ? "Edit StoreMetric" : "Add StoreMetric"}
                            </ModalHeader>
                            <ModalBody>
                                <Form
                                    onSubmit={e => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}
                                >
                                    <Row>
                                        <Col xs={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">Name</Label>
                                                <Input
                                                    name="name"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.name || ""}
                                                    invalid={
                                                        validation.touched.name &&
                                                            validation.errors.name
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.touched.name &&
                                                    validation.errors.name ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.name}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label">Value</Label>
                                                <Input
                                                    name="value"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.value || ""}
                                                    invalid={
                                                        validation.touched.value &&
                                                            validation.errors.value
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.touched.value &&
                                                    validation.errors.value ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.value}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="text-end">
                                                <button
                                                    type="submit"
                                                    className="btn btn-success save-user"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </ModalBody>
                        </Modal>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default withRouter(StoreMetrics);
