import {
  GET_FOOD_ITEMS,
  GET_FOOD_ITEMS_SUCCESS,
  GET_FOOD_ITEMS_FAIL,
  ADD_NEW_FOOD_ITEM,
  ADD_FOOD_ITEM_SUCCESS,
  ADD_FOOD_ITEM_FAIL,
  UPDATE_FOOD_ITEM,
  UPDATE_FOOD_ITEM_SUCCESS,
  UPDATE_FOOD_ITEM_FAIL,
  DELETE_FOOD_ITEM,
  DELETE_FOOD_ITEM_SUCCESS,
  DELETE_FOOD_ITEM_FAIL,
  GET_FOOD_ITEM,
  GET_FOOD_ITEM_SUCCESS,
  GET_FOOD_ITEM_FAIL,
  GET_FOOD_ITEMSCOMBO_SUCCESS,
  GET_FOOD_ITEMSCOMBO_FAIL,
  UPDATE_STATUS_FOOD_ITEM,
  UPDATE_STATUS_FOOD_ITEM_FAIL,
  UPDATE_STATUS_FOOD_ITEM_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  fooditem: [],
  fooditemCombo: [],
  error: {},
  loading: false
}

const fooditems = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FOOD_ITEMSCOMBO_SUCCESS:
      return {
        ...state,
        fooditemCombo: action.payload,
        loading: true
      }
      break
    case GET_FOOD_ITEMSCOMBO_FAIL:
      return {
        ...state,
        error: action.payload,

      }
      break
    case GET_FOOD_ITEMS:
      return {
        ...state,
        loading: true
      }
      break
    case GET_FOOD_ITEMS_SUCCESS:
      return {
        ...state,
        fooditem: action.payload,
        loading: false
      }
      break
    case GET_FOOD_ITEMS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case ADD_NEW_FOOD_ITEM:
      return {
        ...state,
        loading: true
      }
      break
    case ADD_FOOD_ITEM_SUCCESS:

      return {
        ...state,
        fooditem: [...state.fooditem, action.payload],
        loading: false
      }
      break
    case ADD_FOOD_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case UPDATE_FOOD_ITEM:
      return {
        ...state,
        loading: true
      }
      break
    case GET_FOOD_ITEM_SUCCESS:
      return {
        ...state,
        fooditem: action.payload,
        loading: false
      }
      break
    case UPDATE_FOOD_ITEM_SUCCESS:
      return {
        ...state,
        fooditem: state.fooditem.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
        loading: false
      }
      break
    case UPDATE_FOOD_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case DELETE_FOOD_ITEM:
      return {
        ...state,
        loading: true
      }
      break
    case DELETE_FOOD_ITEM_SUCCESS:
      return {
        ...state,
        fooditem: state.fooditem.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
        loading: false
      }
      break
    case DELETE_FOOD_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case GET_FOOD_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case UPDATE_STATUS_FOOD_ITEM:
      return {
        ...state,
        loading: true
      }
      break
    case UPDATE_STATUS_FOOD_ITEM_SUCCESS:
      return {
        ...state,
        fooditem: state.fooditem.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
        loading: false
      }
      break
    case UPDATE_STATUS_FOOD_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    default:
      return state
  }
}

export default fooditems
