import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { ADD_NEW_CATEGORY, DELETE_CATEGORY, GET_CATEGORYS, UPDATE_CATEGORY, GET_CATEGORYSCOMBO, UPDATE_STATUS_CATEGORY  } from "./actionTypes"

import {
  getCategorysSuccess,
  getCategorysFail,
  addCategoryFail,
  addCategorySuccess,
  updateCategorySuccess,
  updateCategoryFail,
  deleteCategorySuccess,
  deleteCategoryFail,
  getCategoryComboSuccess,
  getCategoryComboFail,
  updateStatusCategorySuccess,
  updateStatusCategoryFail
} from "./actions"

//Include Both Helper File with needed methods
import { getCategory, addCategory, updateCategory, deleteCategory, getCategoryCombo, updateStatusCategory } from "../../helpers/category_helper"

// toast
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';



function* fetchCategory() {
  try {
    const response = yield call(getCategory)
    yield put(getCategorysSuccess(response.categories))
  } catch (error) {
    yield put(getCategorysFail(error))
  }
}

function* fetchCategoryCombo() {
  try {
    const response = yield call(getCategoryCombo)
    yield put(getCategoryComboSuccess(response.categories))
  } catch (error) {
    yield put(getCategoryComboFail(error))
  }
}

function* onUpdateCategory({ payload: category }) {
  try {
    const response = yield call(updateCategory, category)
    response.category.image = response.category.image.path;
    response.category.id = response.category._id;
    yield put(updateCategorySuccess(response.category))
    toast.success("Updated Successfully", { autoClose: 2000 });
  } catch (error) {
    console.log(error)
    yield put(updateCategoryFail(error))
    toast.error("Updated Failed", { autoClose: 2000 });
  }
}

function* onDeleteCategory({ payload: category }) {
  try {
    const response = yield call(deleteCategory, category)
    
    yield put(deleteCategorySuccess(response.category._id))
    toast.success("Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteCategoryFail(error))
    toast.error("Deleted Failed", { autoClose: 2000 });
  }
}

function* onAddCategory({ payload: user }) {
  try {
    const response = yield call(addCategory, user)
    response.category.image = response.category.image.path;
    response.category.id = response.category._id;
    yield put(addCategorySuccess(response.category))
    toast.success("Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addCategoryFail(error))
    toast.error("Added Failed", { autoClose: 2000 });
  }
}

function* onUpdateStatusategory({ payload: category }) {
  try {
    console.log(category)
    const response = yield call(updateStatusCategory, category)
    yield put(updateStatusCategorySuccess(response.category))
    toast.success("Updated Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(updateStatusCategoryFail(error))
    toast.error("Updated Failed", { autoClose: 2000 });
  }
}

function* categorySaga() {
  yield takeEvery(GET_CATEGORYS, fetchCategory)
  yield takeEvery(ADD_NEW_CATEGORY, onAddCategory)
  yield takeEvery(UPDATE_CATEGORY, onUpdateCategory)
  yield takeEvery(DELETE_CATEGORY, onDeleteCategory)
  yield takeEvery(GET_CATEGORYSCOMBO, fetchCategoryCombo)
  yield takeEvery(UPDATE_STATUS_CATEGORY, onUpdateStatusategory)
}

export default categorySaga;
