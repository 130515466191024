import React from "react";
import { Navigate } from "react-router-dom";

// Profile

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import Appearance from "pages/Appearance";
import Users from "pages/Users/Users";
import Subscriptions from "pages/Subscription/Subscription";
import Tags from "pages/Tags";
import Category from "pages/Category";
import AlbumList from "pages/Album/AlbumList";
import CreateAlbum from "pages/Album/CreateAlbum";
import Plans from "pages/Subscription/Plans";
import Coupans from "pages/Coupans";
import UserDetails from "pages/Users/UserDetails";
import Slides from "pages/Slides";
import Home from "pages/Home/Home";
import Notification from "pages/Notification";
import Counter from "pages/Counter";
import StoreMetric from "pages/StoreMetric";
import StoreCategory from "pages/StoreCategory";
import StoreItem from "pages/StoreItem";
import FoodItem from "pages/FoodItem";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },


  { path: "/appearance", component: <Appearance /> },

  { path: "/story-list", component: <AlbumList /> },

  { path: "/create-story", component: <CreateAlbum /> },

  { path: "/create-story/:id", component: <CreateAlbum /> },

  { path: "/users", component: <Users /> },

  { path: "/user-details/:id", component: <UserDetails /> },

  { path: "/subscriptions", component: <Subscriptions /> },

  { path: "/subscription-plans", component: <Plans /> },

  { path: "/coupans", component: <Coupans /> },

  { path: "/category", component: <Category /> },

  { path: "/counter", component: <Counter /> },

  { path: "/tags", component: <Tags /> },

  { path: "/slides", component: <Slides /> },

  { path: "/home", component: <Home /> },

  { path : "/store-metric", component: <StoreMetric/> },

  { path : "/store-category", component: <StoreCategory/> },

  { path : "/store-item", component: <StoreItem/> },
  
  { path: "/notification", component: <Notification /> },

  { path: "/food-item", component: <FoodItem /> },
  

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> }
];

export { authProtectedRoutes, publicRoutes };
